/** @format */

import { bindable } from 'aurelia-framework';
import geoService from 'services/api/geoService';

export class ReverseGeo {
  @bindable location;
  @bindable onAddressLoad;
  @bindable showLoader;

  constructor() {
    this.geoService = geoService;
    this.address = null;
    this.displayAddress = '';
    this.loading = false;
    this.showLoader = false;
  }

  showLoaderChanged(value) {
    this.showLoader = value === 'true' || value === true;
  }

  locationChanged(latlng) {
    if (latlng && latlng.length > 1) {
      this.loadAddress(latlng[0], latlng[1]);
    }
  }

  loadAddress(lat, lng) {
    if (lat && lng && !this.loading) {
      this.loading = true;

      this.geoService
        .reverseGeoCoding(lat, lng)
        .then(address => {
          this.loading = false;
          this.address = address;
          if (this.onAddressLoad) {
            this.onAddressLoad(this.address);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
}
