/** @format */

import {
  inject,
  bindable,
  DOM
} from 'aurelia-framework';
import googleService from 'services/googleService';

import './streetview-image.scss';

@inject(DOM.Element)
export class StreetviewImage {
  @bindable latlng;
  @bindable size;

  constructor(Element) {
    this.element = Element;

    this.loaded = false;
    this.image = null;
    this.link = null;

    this.qReady = new Promise(resolve => (this.qReadyResolve = resolve));
  }

  attached() {
    this.autoSize = this.calcSize();
    this.ready = true;
    this.qReadyResolve(true);
  }

  latlngChanged() {
    this.qReady.then(() => {
      if (this.loadImage) {
        this.loadImage()
      }
    });
  }

  loadImage() {
    if (!this.image && this.streetviewImage) {
      this.streetviewImage.style.backgroundImage = "url()";
    }

    if (this.latlng) {
      let [lat, lng] = this.latlng;
      let _size = this.size || this.autoSize;

      if (lat && lng && _size) {
        this.loaded = false;
        return googleService
          .getStreetViewImage(lat, lng, _size)
          .then(_image => {
            this.image = _image;
            if (this.streetviewImage) {
              this.streetviewImage.style.backgroundImage = "url(" + this.image + ")";
            }
            this.loaded = true;
            this.link = `http://maps.google.com/maps?q=&layer=c&cbll=${lat},${lng}&cbp=11,0,0,0,0`;
          }).catch(e => {
            this.image = null;
            if (this.streetviewImage) {
              this.streetviewImage.style.backgroundImage = "url()";
            }
            this.link = '';
            this.loaded = true;
          });
      }
    }
  }

  detached() {
    if (this.streetviewImage) {
      this.streetviewImage.style.backgroundImage = "url()";
    }
  }

  calcSize() {
    if (this.element) {
      let parent = this.element.parentElement;
      if (parent) {
        let w = parent.offsetWidth || 100;
        let h = (parent.offsetHeight || 100) + 30;
        return [w, h];
      }
    }
    return [];
  }

  onClick() {
    if (this.image && this.link) {
      window.open(this.link, '_blank');
    }
  }
}
