import {
  bindable,
  computedFrom,
  inject
} from 'aurelia-framework';
import {
  TranslationService
} from 'services/translationService';
import {
  ConvertUtils
} from '@fonix/web-utils';

import './advanced-grid.scss';

@inject(TranslationService)
export class AdvancedGrid {
  @bindable headers;
  @bindable columns;
  @bindable source;
  @bindable search;
  @bindable searchBox;
  @bindable searchValue;
  @bindable sortProperties;
  @bindable loading;
  @bindable scrollPosition;
  @bindable isScrolling;
  @bindable route;
  @bindable multiSelect;
  @bindable multiSelectActions;
  @bindable noResults;

  @bindable onScrollEnd;
  @bindable onRowClick;

  //TODO
  @bindable onCellSelect;

  constructor(_TranslationService) {
    this.ts = _TranslationService;
    this.accountUnit = ConvertUtils.getUnit('distance') || {
      name: 'km',
      weight: 0
    };

    this.loading = false;
    this.sortProperties = {
      field: '',
      reverse: true
    };
    this.actions = {
      sort: this.sort
    };
    this.search = '';
    this.searchBox = true;
    this.searchValue = '';
    this.isScrolling = false;
    this.scrollPosition = {
      top: 0,
      left: 0
    }
    this.multiSelect = false;
    this.multiSelectAll = false;
    this.multiSelectedItems = [];
    this.multiSelectActions = [
      // {name: 'delete', icon: 'trash', action: this.multiSelectDelete.bind(this)},

    ]

    this.headerClick = this.headerClick.bind(this);
    this._onRowClick;
  }

  @computedFrom('source')
  get pagedSource() {
    return this.source || [];
  }

  @computedFrom('headers')
  get headersArray() {
    return this.headers || [];
  }

  @computedFrom('search')
  get searchFields() {
    if (this.search instanceof Array) {
      return this.search;
    } else {
      return (this.search || '').split(',');
    }
  }

  @computedFrom('multiSelectedItems')
  get multiSelectLabel() {
    return this.ts.getCap('selected').replace('%d', this.multiSelectedItems.length);
  }

  @computedFrom('multiSelectActions')
  get dropdownMultiSelectActions() {
    if (this.multiSelectActions.find(action => (action.dropdown))) {
      return this.multiSelectActions.filter(action => (action.dropdown))
    } else {
      return null;
    }
  }

  @computedFrom('columns')
  get firstColumnImage() {
    let columIndex = this.columns.findIndex(column => (column.type && (column.type == 'image' || column.type == 'icon')));
    return (columIndex != null && columIndex == 0) ? this.columns[columIndex] : false;
  }

  loadingChanged() {
    if (this.route && !this.loading && this.scrollContainerRef) {
      var currentRoute = this.route.navModel.router.routes.find(x => x.name === this.route.name)
      if (currentRoute && (currentRoute.settings.scrollTop || currentRoute.settings.scrollLeft)) {
        setTimeout(() => {
          if (this.scrollContainerRef && this.scrollContainerRef.scrollTo) {
            this.scrollContainerRef.scrollTo(currentRoute.settings.scrollLeft, currentRoute.settings.scrollTop)
          }
          currentRoute.settings.scrollTop = 0;
          currentRoute.settings.scrollLeft = 0;
        });
      } else {
        setTimeout(() => {
          if (this.scrollContainerRef && this.scrollContainerRef.scrollTo) {
            this.scrollContainerRef.scrollTo(this.scrollPosition.left, this.scrollPosition.top)
          }
        });
      }
    }
  }

  _onRowClick(item, index, column) {
    if (this.onRowClick) {
      if (this.route) {
        var currentRoute = this.route.navModel.router.routes.find(x => x.name === this.route.name)
        if (currentRoute && (currentRoute.settings)) {
          currentRoute.settings.scrollTop = this.scrollPosition.top;
          currentRoute.settings.scrollLeft = this.scrollPosition.left;
        }
      }
      this.onRowClick(item, index, column)
    }
    return true;
  }

  headerClick(event, header) {
    let elem = event.target;
    let action = this.actions[elem.dataset.action];
    if (action) {
      action.call(this, elem, header);
    }
  }

  sort(elem, header) {
    let factor = +elem.dataset.sortDir || 1;
    let field = header.sortField || header.label;

    if (this.sortProperties.field != field) {
      this.sortProperties.field = field;
      this.sortProperties.reverse = false;
    } else {
      this.sortProperties.reverse = -factor==-1;
    }
  }

  onScroll(e) {
    if (e.target.scrollTop && e.target.scrollTop > 0) {
      this.isScrolling = true;
    } else {
      this.isScrolling = false;
    }
    this.scrollPosition = {
      top: e.target.scrollTop,
      left: e.target.scrollLeft
    };
    if (this.onScrollEnd) {
      let scrollEndMargin = 20;
      let scrollEnd = e.target.scrollTop >= (e.target.scrollTopMax - scrollEndMargin) ? true : false;
      this.onScrollEnd(scrollEnd);
    }
  }

  searchBoxChanged(value) {
    this.searchBox = (value === true || value === 'true');
  }

  get isSelectable() {
    return this.onRowClick !== null;
  }

  sourceChanged() {
    this.multiSelectAll = false;
    this.multiSelectedItems = [];
  }

  selectAll() {
    if (this.multiSelectAll || this.multiSelectedItems.length != this.source.length) {
      this.source.forEach(item => {
        item.selected = true;
      });
    } else {
      this.source.forEach(item => {
        item.selected = false;
      });
    }

    this.multiSelectedItems = this.source.filter(item => (item.selected))
  }

  selectItem(item) {
    let selectedItem = this.source.find(i => (i.id === item.id))
    selectedItem.selected = (selectedItem.selected) ? false : true;

    this.multiSelectedItems = this.source.filter(item => (item.selected))

    if (this.multiSelectedItems.length == this.source.length && !this.multiSelectAll) {
      this.multiSelectAll = true;
    } else if (this.multiSelectedItems.length != this.source.length && this.multiSelectAll) {
      this.multiSelectAll = false;
    }
  }

  triggerAction(button) {
    if (button && button.action) {
      button.action();
    }
  }
}
